import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-white mb-0 py-8">
      {/* Top horizontal line */}
      <div className="w-full border-t border-white mb-4"></div>

      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col items-center md:flex-row">
          <Link to="/policies" className="text-lg hover:border-white font-semibold hover:underline">
            Privacy Policy
          </Link>
        </div>

        <div className="md:flex flex-col items-center mt-4 md:mt-0">
          <p className="text-sm">&copy; {new Date().getFullYear()} All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
