import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';

const EventModal = ({ event, onClose }) => {
  const modalRef = useRef();

  const defaultImageUrl = 'path_to_default_image';
  const hasImage = event.image && event.image.asset && event.image.asset.url;
  const imageUrl = hasImage ? event.image.asset.url : defaultImageUrl;

  const startDate = moment.tz(event.date.startDate, event.date.timeZone).format('MMM D');
  const startTime = moment.tz(event.date.startDate, event.date.timeZone).format('hA');
  const endTime = event.date.endDate ? moment.tz(event.date.endDate, event.date.timeZone).format('hA') : '';
  const timeZone = moment.tz(event.date.startDate, event.date.timeZone).format('z');

  const formattedTime = `${startTime} - ${endTime} ${timeZone}`;
  const formattedDate = `${startDate}`;

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg p-4 md:p-8 max-w-4xl w-full mx-4 md:mx-0 relative"
      >
        <button
          className="absolute top-4 right-4 text-black"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="md:col-span-2 flex flex-col justify-between">
            <div>
              <h2 className="text-3xl md:text-6xl text-black font-semibold">{event.eventName}</h2>
              <div className="flex justify-between items-center">
                <p className="text-lg text-black">Location: {event.venue}</p>
                <p className="text-lg text-gray-600">{event.city}, {event.state}</p>
              </div>
              <div className="flex justify-between items-center mt-2">
                <p className="text-lg text-gray-600">{formattedDate}</p>
                <p className="text-lg text-gray-600">{formattedTime}</p>
              </div>
              <p className="mt-4 text-md text-gray-700">{event.description}</p>
            </div>
            <div className="hidden md:block">
              <button
                onClick={() => window.open(event.ticketLink, '_blank')}
                className="w-full text-lg px-4 py-2 rounded-md transition-all duration-300 bg-black text-white hover:bg-gray-800"
              >
                RSVP
              </button>
            </div>
          </div>
          {hasImage && (
            <div className="md:col-span-1 flex flex-col items-center">
              <img
                src={imageUrl}
                alt={event.eventName}
                className="rounded-md w-full h-auto max-h-96 object-cover mb-4 md:mb-0"
              />
              <div className="block md:hidden mt-4">
                <button
                  onClick={() => window.open(event.ticketLink, '_blank')}
                  className="w-full text-lg px-4 py-2 rounded-md transition-all duration-300 bg-black text-white hover:bg-gray-800"
                >
                  RSVP
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventModal;
