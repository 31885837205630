import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiX, FiMapPin, FiImage } from 'react-icons/fi';
import { AiFillInstagram, AiOutlineMail } from 'react-icons/ai';
import { FaTiktok } from 'react-icons/fa';
import logo from '../assets/web-logo.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-black text-white">
      <div className="container mx-auto px-2 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">
          <img src={logo} className="h-10 w-100" alt="logo" />
        </Link>
        <button
          className="text-white focus:outline-none md:hidden"
          onClick={handleMenuToggle}
        >
          {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
        {menuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex flex-col items-center justify-center z-50">
            <button
              className="text-white focus:outline-none absolute top-4 right-4"
              onClick={handleMenuToggle}
            >
              <FiX size={24} />
            </button>
            <div className="container mx-auto mt-8 flex flex-col items-center">
              <Link to="/" className="text-2xl font-bold mb-8">
                <img src={logo} className="h-10 w-100" alt="logo" />
              </Link>
              <nav className="text-center">
                <Link
                  to="/locations"
                  className="block mx-4 text-lg py-2 px-2 hover:border-white font-bold hover:underline flex items-center justify-center"
                  onClick={handleMenuToggle}
                >
                  <FiMapPin className="mr-2" size={18} />
                  Locations
                </Link>
                <a
                  href="https://www.instagram.com/blanklocations"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block mx-4 text-lg py-2 px-2 hover:border-white font-bold hover:underline flex items-center justify-center"
                  onClick={handleMenuToggle}
                >
                  <FiImage className="mr-2" size={18} />
                  Gallery
                </a>
              </nav>
              <div className="flex justify-center mt-8 space-x-4">
                <a
                  href="https://www.instagram.com/blanklocations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillInstagram className="text-white text-2xl" />
                </a>
                <a
                  href="https://www.tiktok.com/@blanklocations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok className="text-white text-2xl" />
                </a>
                <a
                  href="mailto:info@blanklocations.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineMail className="text-white text-2xl" />
                </a>
              </div>
            </div>
          </div>
        )}
        <div className={`md:flex md:items-center hidden ${menuOpen ? 'block' : ''}`}>
          <nav className="md:flex md:items-center">
            <Link
              to="/locations"
              className="mx-4 text-lg py-2 px-2 hover:border-white font-bold hover:underline flex items-center"
            >
              <FiMapPin className="mr-2" size={18} />
              Locations
            </Link>
            <a
              href="https://www.instagram.com/blanklocations"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-4 text-lg py-2 px-2 hover:border-white font-bold hover:underline flex items-center"
            >
              <FiImage className="mr-2" size={18} />
              Gallery
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
