import React from 'react';
import { useForm } from 'react-hook-form';
import sanityClient from '@sanity/client';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PartnerApplication = () => {
  const { register, handleSubmit, reset } = useForm(); // Use reset function to clear the form

  const onSubmit = async (data) => {
    try {
      const client = sanityClient({
        projectId: '0pu9yr4y',
        dataset: 'events',
        apiVersion: 'v2021-08-20',
        useCdn: false,
        token: 'skdXXNJnoyHMKsJFenoStrMd7LAHMD0l0HtlxltJGOhU8R9IGx7an6qHNRSnzhezuN5Cu5eUJIJl01LEuEl52dX7dzcNrKhBDTa5h1wRUfwgTttellrdA54nIdlxvC3oQlGpWqH50xmj9A5Pu4eCBcE0q6rPaXi0LWGhFV9KRikL66BUiZdE',
      });

      const imageAsset = await client.assets.upload('image', data.companyLogo[0]);

      const response = await client.create({
        _type: 'partner',
        ...data,
        companyLogo: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: imageAsset._id,
          },
        },
      });

      if (response) {
        toast.success('Application submitted successfully!', {
          autoClose: 3000,
        });

        // Clear the form on successful submission
        reset();
      } else {
        toast.error('Application submission failed.', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('Application submission failed.', {
        autoClose: 3000,
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-lg mx-auto mt-8 p-8 bg-black text-white"
    >
      <h2 className="text-4xl font-bold mb-4">Contact Us </h2>
      <p className="text-xl mb-4">
        Become a partner with Blank Locations and connect with our vibrant community.
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <input
          type="text"
          name="companyName"
          placeholder="Company Name"
          {...register('companyName', { required: true })}
          className="w-full border-b-2 focus:outline-none focus:border-white bg-black text-white"
        />

        <div className="flex space-x-2">
          <input
            type="text"
            name="contactFirstName"
            placeholder="First Name"
            {...register('contactFirstName', { required: true })}
            className="flex-1 border-b-2 focus:outline-none focus:border-white bg-black text-white"
          />
          <input
            type="text"
            name="contactLastName"
            placeholder="Last Name"
            {...register('contactLastName', { required: true })}
            className="flex-1 border-b-2 focus:outline-none focus:border-white bg-black text-white"
          />
        </div>

        <input
          type="email"
          name="contactEmail"
          placeholder="Contact Email"
          {...register('contactEmail', { required: true })}
          className="w-full border-b-2 focus:outline-none focus:border-white bg-black text-white"
        />

        <input
          type="text"
          name="companyIndustry"
          placeholder="Company Industry"
          {...register('companyIndustry', { required: true })}
          className="w-full border-b-2 focus:outline-none focus:border-white bg-black text-white"
        />

        <textarea
          name="companyDescription"
          placeholder="Company Description"
          {...register('companyDescription', { required: true })}
          className="w-full border-b-2 focus:outline-none focus:border-white bg-black text-white"
        />

        <input
          type="file"
          name="companyLogo"
          accept="image/*"
          {...register('companyLogo', { required: true })}
          className="w-full border-b-2 focus:outline-none focus:border-white bg-black text-white"
        />

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          className="w-full py-2 bg-white text-black font-semibold hover:bg-black hover:text-white transition duration-300"
        >
          Submit
        </motion.button>
      </form>
      <ToastContainer />
    </motion.div>
  );
};

export default PartnerApplication;
