import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white text-black p-8"
    >
      <h2 className="text-6xl sm:text-5xl font-bold mb-6 text-center">What We Do</h2>
      <div className="text-xl sm:text-xl font-semibold leading-relaxed text-justify">
        Blank Locations is your gateway to exceptional experiences. We curate vibrant settings where connections are forged, inspiration is ignited, and success is celebrated.

        <br /><br />

        Join us in empowering people to thrive, connect, and unlock their full potential. We're a community that brings together individuals from diverse backgrounds and industries, whether for networking, launching your business, or simply enjoying a night out.

        <br /><br />

        <div className="text-center mt-6">
          <Link to="/joinus">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-black text-white py-2 px-6 font-bold hover:bg-white hover:text-black hover:border-black border border-black rounded-md"
            >
              Join Us
            </motion.button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
