import React from 'react';

const Policy = () => {
  return (
    <div className="container mx-auto px-4 py-8 text-white">
      <h2 className="text-2xl font-bold mb-4">Blank Locations Event & Ticket Policies</h2>

      <h3 className="text-xl font-bold mb-2">Welcome to Blank Locations!</h3>
      <p className="mb-4">
        At Blank Locations, we want you to have a fantastic experience at our events. Please take
        a moment to review our policies to ensure a smooth and enjoyable time.
      </p>

      <h3 className="text-xl font-bold mb-2">Event Expectations and Rules</h3>
      <p className="mb-4">
        Be Your Awesome Self: We encourage you to relax, have fun, and be yourself at our events.
        Come in peace and let your hair down!
      </p>

      <p className="mb-4">
        Respect and Inclusivity: Blank Locations is a judgment-free and harassment-free zone. We
        are committed to providing a safe and inclusive space for everyone.
      </p>

      <p className="mb-4">
        Age Restriction: Unless explicitly stated otherwise, all of our events are strictly for
        adults aged 21 and above. Sorry, kids, but we'll catch you when you're older!
      </p>

      <h3 className="text-xl font-bold mb-2">Ticketing and Admission</h3>
      <p className="mb-4">
        Ticketing Process: When you purchase Blank Locations tickets through our platform, your
        name will be added to our guest list. No need to worry about email confirmations; we've got
        you covered!
      </p>

      <h4 className="text-lg font-bold mb-2">Refund Policy:</h4>
      <p className="mb-4">
        No Refunds: All ticket sales are final and non-refundable. We operate under a strict
        no-refund policy, including all ticket categories such as general admission, VIP, and early
        bird.
      </p>

      <p className="mb-4">
        Event Date Changes: If an event date or time changes, your ticket remains valid for the
        rescheduled event. If you can't make it to the new date, our no-refund policy still applies.
      </p>

      <p className="mb-4">
        Event Cancellation: In rare cases of event cancellation by Blank Locations, you'll receive
        a credit for the full ticket price, valid for a future event. No cash refunds will be
        provided.
      </p>

      <p className="mb-4">
        Non-Transferrable: Tickets are non-transferrable between individuals and are valid only for
        the person named on the ticket.
      </p>

      <p className="mb-4">
        Force Majeure: We won't provide refunds or compensation if events are canceled, postponed,
        or altered due to circumstances beyond our control.
      </p>

      <p className="mb-4">
        Ticket Resale: Please refrain from ticket resale or scalping, as it's strictly prohibited.
        Tickets from unauthorized sources will not be honored.
      </p>

      <p className="mb-4">
        Lost or Stolen Tickets: Keep your tickets safe; we're not responsible for lost, stolen, or
        misplaced tickets. Make sure your contact information is accurate during the purchase
        process for event-related updates.
      </p>

      <h4 className="text-lg font-bold mb-2">Cancelled Events:</h4>
      <p className="mb-4">
        If we cancel an event, you don't need to do anything; we'll issue a refund to your original
        payment method once we receive funds from the Event Organizer.
      </p>

      <h4 className="text-lg font-bold mb-2">Postponed Events:</h4>
      <p className="mb-4">
        For postponed, rescheduled, or relocated events, your tickets remain valid for the new date.
        We'll email ticket holders with refund options if available.
      </p>

      <h3 className="text-xl font-bold mb-2">Disputed Charge Policy</h3>
      <p className="mb-4">
        We appreciate your support and want to ensure a seamless experience with Blank Locations.
        Here's our Disputed Charge Policy:
      </p>

      <p className="mb-4">
        Communication Channels: If you have any payment-related issues, contact our Customer Support
        team at info@blanklocations.com. We're here to help resolve concerns promptly and
        professionally.
      </p>

      <p className="mb-4">
        Chargeback Prevention: Before initiating a chargeback, reach out to our Customer Support
        team. We'll investigate and work to find a resolution that satisfies you.
      </p>

      <p className="mb-4">
        Resolution: If an error occurred on our end, we'll initiate a refund or corrective action.
        We aim to resolve disputes fairly and efficiently.
      </p>

      <p className="mb-4">
        Chargeback Consequences: Initiate a chargeback without contacting us first, and we may
        suspend your access to future events. You may also be responsible for chargeback fees.
      </p>

      <p className="mb-4">
        Fraud Prevention: We take fraud prevention seriously. Contact us if you suspect unauthorized
        activity on your account.
      </p>

      <p className="mb-4">
        Cooperation: We value open communication. Please communicate directly with our Customer
        Support team to address any concerns.
      </p>

      <p className="mb-4">
        By making a purchase with Blank Locations, you acknowledge and agree to our policies. We
        encourage you to contact our Customer Support team for assistance.
      </p>

      <h3 className="text-xl font-bold mb-2">Policy Updates:</h3>
      <p className="mb-4">
        Blank Locations reserves the right to make changes to these policies at its sole discretion.
        Any changes will be effective upon posting on the Blank Locations website.
      </p>

      <p className="mb-4">
        If you have any questions or concerns, please reach out to us at info@blanklocations.com.
      </p>

      <p className="mb-4">Thank you for choosing Blank Locations. We look forward to hosting you at our events!</p>
    </div>
  );
};

export default Policy;
