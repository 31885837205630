import React from 'react';
import { motion } from 'framer-motion';

const Platform = () => {
  return (
    <div className="container mx-auto p-8 text-white">
      <h1 className="text-4xl font-bold mb-8">
        Our Platform
      </h1>

      <p className="text-lg mb-8">
        Blank Locations is more than just a platform; it's a digital playground where you can explore a curated world of nightlife, connect with like-minded individuals, and build lasting relationships. This is all made possible by our powerful features designed to enhance your experience:
      </p>

      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4">Event Discovery</h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Curated Calendar:</strong> Discover a diverse range of events, from vibrant club nights to intimate gatherings, pop-up experiences, and networking opportunities, all carefully curated to cater to your interests.
          </li>
          <li>
            <strong>Advanced Search:</strong> Utilize advanced search filters to find events by genre, location, date, and your interests, ensuring you never miss out on what excites you.
          </li>
          <li>
            <strong>Personalized Recommendations:</strong> Receive tailored event suggestions based on your past activity and preferences, allowing you to explore new experiences and rediscover hidden favorites.
          </li>
        </ul>
      </motion.div>

      {/* Add motion.div for Meaningful Connections, Elevated Experiences, and Interactive Features similarly */}

      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4">Meaningful Connections</h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Member Directory:</strong> Search and connect with like-minded individuals based on shared interests, passions, and professional backgrounds.
          </li>
          <li>
            <strong>Activity Feed:</strong> Engage in meaningful conversations, share experiences, and stay updated on the latest happenings within the community.
          </li>
          <li>
            <strong>Direct Messaging:</strong> Initiate private conversations with fellow members to build deeper connections and foster relationships.
          </li>
        </ul>
      </motion.div>

      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4">Elevated Experiences</h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Priority RSVPs:</strong> Secure your spot for coveted events before they sell out, ensuring you never miss out on the hottest happenings.
          </li>
          <li>
            <strong>Exclusive Discounts:</strong> Enjoy member-exclusive discounts on event tickets, drinks, and merchandise, saving you money while you experience the best.
          </li>
          <li>
            <strong>VIP Access:</strong> Gain access to exclusive member-only events, concierge services, and dedicated spaces, elevating your Blank Locations experience.
          </li>
        </ul>
      </motion.div>

      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4">Interactive Features</h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Music Discovery:</strong> Explore curated playlists and discover new artists tailored to your musical preferences, adding a new dimension to your nightlife experience.
          </li>
          <li>
            <strong>Event Reviews and Ratings:</strong> Share your feedback and help fellow members discover hidden gems and avoid disappointments.
          </li>
          <li>
            <strong>Personalized Notifications:</strong> Stay informed with timely updates on upcoming events, RSVP confirmations, and important announcements.
          </li>
        </ul>
      </motion.div>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        These are just a few of the powerful features that make Blank Locations the ultimate platform for young professionals seeking exceptional nightlife experiences and meaningful connections. Sign up today and discover a world of possibilities waiting to be explored.
      </motion.p>
    </div>
  );
};

export default Platform;
