import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Partners = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-screen-xl mx-auto mt-8 p-8 bg-black text-white"
    >
      <motion.h2
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="text-4xl font-bold mb-4"
      >
        Partner with Blank Locations and reach hundreds of young professionals.
      </motion.h2>
      <motion.p
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
        className="text-lg mb-4"
      >
        Drive sales, build relationships, and boost brand awareness with our engaged community.
      </motion.p>

      {/* Section: Ready to Partner */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6 }}
        className="text-center mb-8"
      >
        <motion.h3 className="text-xl font-bold mb-2">Ready to Partner?</motion.h3>
        <Link to="/partner-application">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="button"
            className="w-full py-2 bg-white text-black font-semibold rounded-md hover:bg-black hover:text-white transition duration-300"
          >
            Contact Us
          </motion.button>
        </Link>
      </motion.div>

      {/* Section: Partnership Opportunities */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.8 }}
        className="flex flex-col md:flex-row"
      >
        {/* Section: Partnership Opportunities */}
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-black text-white p-4 mb-4 border border-white rounded-md shadow-md md:flex-1 md:mr-4"
        >
          <motion.h3 className="text-2xl font-bold mb-2">Partnership Opportunities</motion.h3>
          <motion.div className="mb-4">
            <motion.h4 className="text-lg font-bold">Exclusive Offers</motion.h4>
            <p>Provide members special discounts and promotions.</p>
          </motion.div>
          <motion.div className="mb-4">
            <motion.h4 className="text-lg font-bold">Event Sponsorships</motion.h4>
            <p>Gain significant brand exposure and connect with attendees.</p>
          </motion.div>
          <motion.div className="mb-4">
            <motion.h4 className="text-lg font-bold">Content Collaborations</motion.h4>
            <p>Partner on blog posts, podcasts, and social media campaigns.</p>
          </motion.div>
          <motion.div className="mb-4">
            <motion.h4 className="text-lg font-bold">Member Benefits</motion.h4>
            <p>Offer exclusive access to your products or services.</p>
          </motion.div>
          <motion.div>
            <motion.h4 className="text-lg font-bold">Co-branding Initiatives</motion.h4>
            <p>Develop co-branded products, services, or experiences.</p>
          </motion.div>
        </motion.div>

        {/* Section: Why Partner with Blank Locations */}
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-black text-white p-4 mb-4 rounded-md shadow-md md:flex-1"
        >
          <motion.h3 className="text-xl font-bold mb-2">Why Partner with Blank Locations?</motion.h3>
          <motion.ul className="list-disc pl-6">
            <motion.li className="mb-2">Trusted brand with a rapidly growing community.</motion.li>
            <motion.li className="mb-2">Targeted marketing expertise and measurable results.</motion.li>
            <motion.li>Dedicated support to ensure your partnership success.</motion.li>
          </motion.ul>
        </motion.div>
      </motion.div>

      <ToastContainer />
    </motion.div>
  );
};

export default Partners;
