import React from 'react';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';

const EventCard = ({ event, isExpanded, handleToggleExpand, className }) => {
  const defaultImageUrl = 'path_to_default_image';
  const hasImage = event.image && event.image.asset && event.image.asset.url;
  const imageUrl = hasImage ? event.image.asset.url : defaultImageUrl;

  const startDate = moment.tz(event.date.startDate, event.date.timeZone).format('MMM D');
  const startTime = moment.tz(event.date.startDate, event.date.timeZone).format('hA');
  const endTime = event.date.endDate ? moment.tz(event.date.endDate, event.date.timeZone).format('hA') : '';
  const timeZone = moment.tz(event.date.startDate, event.date.timeZone).format('z');

  const formattedTime = `${startTime} - ${endTime} ${timeZone}`;
  const formattedDate = `${startDate}`;


  return (
    <motion.div
      className={`relative group border border-white rounded-lg overflow-hidden shadow-lg transition-all duration-300 ${className} ${
        isExpanded ? 'bg-white text-black' : 'bg-black text-white'
      }`}
      onClick={() => handleToggleExpand(event._id)}
      whileHover={{ scale: 1.005 }}
      style={{
        width: '100%', // Adjust based on screen size
        maxWidth: '600px', // Max width for desktop
        height: '200px', // Fixed height
      }}
    >
      <div
        className="absolute inset-0 z-0"
        style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.2 }}
      />
      <div className={`relative z-10 p-4 md:p-8 grid grid-cols-1 gap-4 w-full h-full`}>
        {/* Event details */}
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-2xl md:text-3xl font-semibold">{event.eventName}</h2>
          <p className="text-lg text-gray-400">Location: {event.venue}</p>
          <p className={`text-lg font-bold ${isExpanded ? 'text-black' : 'text-white'}`}>
            {event.city}, {event.state}
          </p>
          {!isExpanded && (
            <div className="mt-2">
              <p className="text-lg text-white">{formattedDate}</p>
            </div>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 md:pt-8 w-full">
          {hasImage && (
            <div className="md:col-span-1 flex items-center justify-center">
              <img
                src={imageUrl}
                alt={event.eventName}
                className="rounded-md w-full h-auto max-h-96 object-cover"
              />
            </div>
          )}
          <div className="md:col-span-2 flex flex-col justify-between">
            <div>
              <p className="text-lg text-black">{formattedDate}</p>
              <p className="text-lg text-black">{formattedTime}</p>
              <p className="mt-4 text-md text-gray-700">Here is a brief description of what the event will look like.</p>
            </div>
            <div className="mt-4">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(event.ticketLink, '_blank');
                }}
                className="text-lg px-4 py-2 rounded-md transition-all duration-300 bg-black text-white hover:bg-gray-800"
              >
                RSVP
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default EventCard;
