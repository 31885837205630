import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Footer from './components/Footer';
import Locations from './components/Locations';
import Events from './components/Events';
import Policies from './components/Policies';
import JoinUs from './components/JoinUs';
import NotFound from './components/NotFound';
import Partners from './components/Partners';
import PartnerApplication from './components/PartnerApplication';
import Shop from './components/Shop';
import FAQ from './components/FAQ';
import Platform from './components/Platform';
import EventPage from './components/EventPage';
import Sounds from './components/Sounds'; // Import the Sounds component

const Home = () => {
  const controls = useAnimation();

  useEffect(() => {
    // Animation for Home component
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
    >
      <Hero />
      <Events />
      <About />
    </motion.div>
  );
};

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/partner-application" element={<PartnerApplication />} />
          <Route path="/frequently-asked-questions" element={<FAQ />} />
          <Route path="/our-platform" element={<Platform />} />
          <Route path="/sounds" element={<Sounds />} /> {/* Route for Sounds */}
          <Route path="*" element={<NotFound />} />
          <Route path="/event/:eventId" element={<EventPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
