import React from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import sanityClient from '@sanity/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JoinUs = () => {
  const { register, handleSubmit, reset, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      // Use your Sanity project configuration
      const client = sanityClient({
        projectId: '0pu9yr4y',
        dataset: 'events',
        apiVersion: 'v2021-08-20',
        useCdn: false,
        token: 'skdXXNJnoyHMKsJFenoStrMd7LAHMD0l0HtlxltJGOhU8R9IGx7an6qHNRSnzhezuN5Cu5eUJIJl01LEuEl52dX7dzcNrKhBDTa5h1wRUfwgTttellrdA54nIdlxvC3oQlGpWqH50xmj9A5Pu4eCBcE0q6rPaXi0LWGhFV9KRikL66BUiZdE',
      });

      // Check if the email is already in the waitlist
      const existingWaitlistEntry = await client
        .fetch('*[_type == "waitlist" && email == $email]', { email: data.email });

      if (existingWaitlistEntry.length > 0) {
        toast.warning('You are already on the waitlist!', {
          autoClose: 3000,
        });
      } else {
        // If not, add the email to the waitlist
        await client.create({
          _type: 'waitlist',
          email: data.email,
        });

        toast.success('Successfully joined the waitlist!', {
          autoClose: 3000,
        });

        // Clear the form on successful submission
        reset();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Submission failed. Please try again.', {
        autoClose: 3000,
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-full sm:max-w-2xl mx-auto mt-4 p-8 bg-black text-white"
    >
      <h2 className="text-4xl font-bold mb-4">Membership Launching 2024</h2>
      <p className="mb-4">
        Blank Locations is your passport to unforgettable nightlife experiences and meaningful connections.
        Join us on this journey and be part of an exclusive community of young professionals.
      </p>

      {/* App Summary */}
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-2">What Awaits You:</h3>
        <ul className="list-disc list-inside">
          <li>Access to curated events, parties, and unique experiences</li>
          <li>Connect with a diverse community of like-minded individuals</li>
          <li>Build genuine relationships through events and networking</li>
          <li>Unlock exclusive member benefits and spaces</li>
        </ul>
      </div>

      {/* Waitlist Form */}
      <motion.form
        onSubmit={handleSubmit(onSubmit)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="space-y-4"
      >
        <motion.input
          type="email"
          name="email"
          placeholder="Enter your email to join the waitlist"
          {...register('email', { required: true })}
          className="w-full border-b-2 focus:outline-none focus:border-white bg-black text-white py-2 px-4"
          style={{
            backgroundColor: 'transparent',
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        />

        <motion.button
          type="submit"
          className="w-full py-2 bg-white text-black font-semibold hover:bg-black hover:text-white transition duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Join Waitlist
        </motion.button>
      </motion.form>
      <ToastContainer />
    </motion.div>
  );
};

export default JoinUs;
