import React from 'react';

const EventCard = ({ event }) => {
  console.log('Event data:', event); // Logging event data

  // Define a default image URL or keep it empty if you prefer not to display an image when none is available
  const defaultImageUrl = 'path_to_default_image'; // Replace with your default image URL
  const hasImage = event.image && event.image.asset && event.image.asset.url;
  const imageUrl = hasImage ? event.image.asset.url : defaultImageUrl;

  const backgroundImageStyle = hasImage
    ? {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backdropFilter: 'blur(8px)',
      }
    : {};

  return (
    <div className="relative group border border-gray-700 rounded-lg overflow-hidden shadow-lg mb-4 bg-black text-white">
      {/* Desktop view - Blurred background image on the right */}
      {hasImage && (
        <div
          className="hidden md:block absolute right-0 top-0 bottom-0 w-1/2"
          style={backgroundImageStyle}
        ></div>
      )}
      {/* Desktop view - Full image on the right */}
      {hasImage && (
        <img
          src={imageUrl}
          alt={event.eventName}
          className="hidden md:block absolute right-0 top-0 bottom-0 w-1/2 object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out"
        />
      )}
      {/* Content container */}
      <div className="p-4 md:p-8 flex md:flex-row items-center">
        {/* Event details */}
        <div className="flex-1">
          <h2 className="text-xl md:text-2xl font-semibold">{event.eventName}</h2>
          <p className="text-lg md:text-xl text-gray-400 mb-1">Location: {event.venue}</p>
          <p className="text-lg md:text-xl text-white font-bold mb-1">
            {event.date.startDate} - {event.date.endDate}
          </p>
          <button
            onClick={() => window.open(event.ticketLink, '_blank')}
            className="bg-white text-black text-lg md:text-xl px-4 py-2 rounded-md hover:bg-gray-200"
          >
            RSVP
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
