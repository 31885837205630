import React from 'react';

const Shop = () => {
  return (
    <div className="flex items-center justify-center h-screen relative">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center z-10">
        <h1 className="text-white text-4xl font-bold mb-8">Summer 2024</h1>
        <form className="bg-black p-4 rounded-md">
          <label className="text-white block mb-2"></label>
          <input
            type="email"
            placeholder="Email Address"
            className="w-full py-2 px-4 border-white rounded-md"
          />
        </form>
      </div>
    </div>
  );
};

export default Shop;
