import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import EventCard from './EventCard';
import EventModal from './EventModal';

const Locations = () => {
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showMorePastEvents, setShowMorePastEvents] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLocationData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          'https://0pu9yr4y.api.sanity.io/v2021-08-20/data/query/events',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: `*[_type == 'location']{
                _id,
                locationName,
                events[]-> {
                  _id,
                  eventName,
                  date {
                    startDate,
                    endDate,
                    timeZone
                  },
                  image {
                    asset->{
                      _id,
                      url
                    }
                  },
                  ticketLink,
                  venue,
                  city,
                  state,
                  description
                }
              } | order(locationName asc)`
            }),
          }
        );

        const data = await response.json();
        setLocationData(data.result || []);
      } catch (error) {
        console.error('Error fetching location data:', error);
        setLocationData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocationData();
  }, []);

  const filterAndSortEvents = (events) => {
    const now = moment();
    const upcomingEvents = events
      .filter(event => {
        const eventEnd = event.date.endDate ? moment.tz(event.date.endDate, event.date.timeZone || 'UTC') : moment.tz(event.date.startDate, event.date.timeZone || 'UTC').endOf('day');
        return eventEnd.isAfter(now);
      })
      .sort((a, b) => moment(a.date.startDate).diff(moment(b.date.startDate)));

    const pastEvents = events
      .filter(event => {
        const eventEnd = event.date.endDate ? moment.tz(event.date.endDate, event.date.timeZone || 'UTC') : moment.tz(event.date.startDate, event.date.timeZone || 'UTC').endOf('day');
        return eventEnd.isBefore(now);
      })
      .sort((a, b) => moment(b.date.startDate).diff(moment(a.date.startDate)));

    return { upcomingEvents, pastEvents };
  };

  const selectedLocationData = selectedLocation
    ? locationData.find(loc => loc._id === selectedLocation)
    : null;

  const { upcomingEvents, pastEvents } = selectedLocationData && selectedLocationData.events
    ? filterAndSortEvents(selectedLocationData.events)
    : { upcomingEvents: [], pastEvents: [] };

  const allUpcomingEvents = locationData.flatMap(location =>
    location.events.filter(event => {
      const eventEnd = event.date.endDate ? moment.tz(event.date.endDate, event.date.timeZone || 'UTC') : moment.tz(event.date.startDate, event.date.timeZone || 'UTC').endOf('day');
      return eventEnd.isAfter(moment());
    })
  ).sort((a, b) => moment(a.date.startDate).diff(moment(b.date.startDate)));

  const allPastEvents = locationData.flatMap(location =>
    location.events.filter(event => {
      const eventEnd = event.date.endDate ? moment.tz(event.date.endDate, event.date.timeZone || 'UTC') : moment.tz(event.date.startDate, event.date.timeZone || 'UTC').endOf('day');
      return eventEnd.isBefore(moment());
    })
  ).sort((a, b) => moment(b.date.startDate).diff(moment(a.date.startDate))).slice(0, 6);

  const handleLocationChange = (e) => {
    const locationId = e.target.value;
    setSelectedLocation(locationId);
    setSelectedEvent(null);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-black">
        <div className="spinner"></div>
        <style jsx>{`
          .spinner {
            border: 4px solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            border-top: 4px solid #fff;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen p-4 md:p-8 bg-black text-white"
    >
      <div className="mb-8">
        <div className="hidden md:flex justify-center space-x-8 border-b border-gray-700">
          <motion.button
            whileHover={{ scale: 1.05 }}
            className={`px-4 py-2 ${!selectedLocation ? 'border-b-2 border-white' : 'text-gray-500'}`}
            onClick={() => {
              setSelectedLocation(null);
              setSelectedEvent(null);
            }}
          >
            All Locations
          </motion.button>
          {locationData.map(location => (
            <motion.button
              whileHover={{ scale: 1.05 }}
              key={location._id}
              className={`px-4 py-2 ${selectedLocation === location._id ? 'border-b-2 border-white' : 'text-gray-500'}`}
              onClick={() => {
                setSelectedLocation(location._id);
                setSelectedEvent(null);
              }}
            >
              {location.locationName}
            </motion.button>
          ))}
        </div>
        <div className="md:hidden">
          <select
            className="w-full px-4 py-2 bg-black border border-gray-700 rounded text-white"
            onChange={handleLocationChange}
            value={selectedLocation || ''}
          >
            <option value="">All Locations</option>
            {locationData.map(location => (
              <option key={location._id} value={location._id}>
                {location.locationName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Upcoming Events</h2>
        <div className="relative">
          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-4"
            initial={{ x: 0 }}
            animate={{ x: 0 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            {(selectedLocation ? upcomingEvents : allUpcomingEvents).map((event, index) => (
              <motion.div
                key={event._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <EventCard
                  event={event}
                  handleToggleExpand={() => setSelectedEvent(event)}
                  className="w-full"
                />
              </motion.div>
            ))}
          </motion.div>
          {(selectedLocation ? upcomingEvents : allUpcomingEvents).length === 0 && (
            <p className="text-lg">We'll be back very soon! Stay Tuned</p>
          )}
        </div>
      </div>

      {selectedLocation && pastEvents.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4">Past Events</h2>
          <div className="relative">
            <motion.div
              className="grid grid-cols-1 md:grid-cols-3 gap-4"
              initial={{ x: 0 }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              {pastEvents.slice(0, showMorePastEvents ? pastEvents.length : 6).map((event, index) => (
                <motion.div
                  key={event._id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <EventCard
                    event={event}
                    handleToggleExpand={() => setSelectedEvent(event)}
                    className="w-full"
                  />
                </motion.div>
              ))}
            </motion.div>
            {pastEvents.length > 6 && !showMorePastEvents && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => setShowMorePastEvents(true)}
                  className="text-lg px-4 py-2 rounded-md transition-all duration-300 bg-black text-white hover:bg-gray-800"
                >
                  Show More
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {!selectedLocation && (
        <div className="mb-8">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4">Past Events</h2>
          <div className="relative">
            <motion.div
              className="grid grid-cols-1 md:grid-cols-3 gap-4"
              initial={{ x: 0 }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              {allPastEvents.map((event, index) => (
                <motion.div
                  key={event._id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <EventCard
                    event={event}
                    handleToggleExpand={() => setSelectedEvent(event)}
                    className="w-full"
                  />
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      )}

      {selectedEvent && (
        <EventModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </motion.div>
  );
};

export default Locations;