import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Events = () => {
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(
          'https://0pu9yr4y.api.sanity.io/v2021-08-20/data/query/events',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: `*[_type == 'event' && date.startDate > now()] | order(date.startDate asc) [0...6]`, // Limit to 6 events
            }),
          }
        );

        const data = await response.json();
        setEventData(data.result);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available'; // Display a message if the date is null
    const options = { day: 'numeric', month: 'short' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className="pb-20 px-10">
      <motion.h1
        className="text-6xl font-bold text-white mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Here's what we have coming up...
      </motion.h1>
      <div className="flex flex-wrap px-4 -mx-4">
        {eventData.map((event) => (
          <motion.div
            key={event._id}
            className="w-full md:w-1/2 lg:w-1/3 p-4 border border-gray-300 rounded-lg cursor-pointer relative text-white mb-8" // Adjusted margin bottom for spacing
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3 className="text-3xl font-bold">{event.eventName}</h3>
            <div className="mt-4">
              <p className="text-2xl">{event.venue}</p>
              <p className="text-xl text-gray-500">
                {event.city}, {event.state}
              </p>
            </div>
            <div className="mt-4 flex justify-between">
              <p className="text-2xl">{formatDate(event.date.startDate)}</p>
              <button
                onClick={() => window.open(event.ticketLink, '_blank')}
                className="text-lg bg-white text-black border border-black px-3 py-1 rounded"
              >
                Get Tickets
              </button>
            </div>
          </motion.div>
        ))}
      </div>
      <div className="mt-12 text-center">
        <Link to="/locations">
          <motion.button
            className="bg-black text-white border-white border-2 hover:bg-white hover:text-black font-bold py-2 px-4 rounded"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            Show All Events
          </motion.button>
        </Link>
      </div>
    </div>
  );
};

export default Events;
