import React, { useState, useEffect } from 'react';

const cities = [
  'DC',
  'Atlanta',
  'Chicago',
  'New York',
  'Los Angeles',
  'Charlotte',
  'Houston',
  'Miami',
  'Dallas',
  'Seattle',
  'Toronto',
  'Philadelphia',
];

const Hero = () => {
  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const [isFading, setIsFading] = useState(true);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    const cityInterval = setInterval(() => {
      setIsFading(true);

      setTimeout(() => {
        setCurrentCityIndex((prevIndex) => (prevIndex + 1) % cities.length);
        setIsFading(false);
      }, 1000);

      setTimeout(() => {
        setShowPlaceholder(false);
      }, 2500);

    }, 2000);

    return () => {
      clearInterval(cityInterval);
    };
  }, [currentCityIndex]);

  return (
    <div className="hero-container relative h-screen overflow-hidden px-4 md:px-8"> {/* Added padding */}
      <div className="text-white flex flex-col items-left justify-center h-screen relative z-10">
        <h1 className="text-5xl md:text-8xl font-extrabold text-left">
          <span className="block md:inline">Let's meet in</span>{' '}
          <span className={`underline ${isFading ? 'animate-fade-in' : 'animate-fade-out'}`} style={{ animationDuration: '2s' }}>
            {showPlaceholder ? '_______' : cities[currentCityIndex]}
          </span>
          .
        </h1>
      </div>
    </div>
  );
};

export default Hero;
