// Sounds.js
import React from 'react';

const Sounds = () => {
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Sounds</h1>
      {/* Add your content for the Sounds page */}
      <p>This is where your Sounds content will go.</p>
    </div>
  );
};

export default Sounds;
